@font-face {
  font-family: "SF Hollywood Hills";
  font-style: normal;
  font-weight: normal;
  src: local("SF Hollywood Hills"),
    url("./fonts/SF\ Hollywood\ Hills.woff") format("woff");
}

@import url("https://fonts.googleapis.com/css2?family=Caveat&family=Oswald:wght@300;400;600&display=swap");

body {
  margin: 0;
  font-family: "Oswald", sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.MuiDialogContent-root .MuiList-root {
  background: #fff !important;
}
.MuiDialogContent-root .MuiListItem-root {
  border-bottom: 1px solid lightgray;
}

.MuiDialogTitle-root {
  background-color: #efc8df !important;
}

.MuiIconButton-root .MuiSvgIcon-root {
  fill: white;
}

.MuiButton-containedPrimary {
  background-color: #efc8df !important;
}
